import React from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Mockup1 from '../../images/home/Mockup1.png'
import Mockup2 from '../../images/home/Mockup2.png'
import Mockup3 from '../../images/home/Mockup3.png'
import Mockup from '../../images/home/Mockup.png'



function BikeCarousel() {
    return (
        <Carousel 
            autoPlay={true} 
            infiniteLoop={true} 
            interval={5000}
            showArrows={false}
            showStatus={false}
            showThumbs={false}
        >
            <div>
                <img src={Mockup1} alt='mockup'/>
            </div>
            <div>
                <img src={Mockup2} alt='mockup'/>
            </div>
            <div>
                <img src={Mockup3} alt='mockup'/>
            </div>
            <div>
                <img src={Mockup} alt='mockup'/>
            </div>
        </Carousel>
    )
}

export default BikeCarousel