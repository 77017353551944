import React from 'react'
import TopSection from '../components/TopSection'

function HomePage() {
  return (
    <div>
        <TopSection/>
    </div>
  )
}

export default HomePage