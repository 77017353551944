import React from 'react'

function ShippingAddress() {
  return (
    <div className='pt-[100px]'>
        <div>ShippingAddress</div>
        <div>ShippingAddress</div>
        <div>ShippingAddress</div>
    </div>
  )
}

export default ShippingAddress