import React from 'react'
import OrderList from '../../pages/OrderList'

function OrderHistory() {
  return (
    <div>OrderHistory
        <OrderList/>
    </div>
  )
}

export default OrderHistory