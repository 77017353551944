const productTypes = [
    {
      value:'BIKE',
      label:'Bike'
    },
    {
      value:'ACCESSORY',
      label:'Accessory'
    },
    {
      value:'INSURANCE',
      label:'Insurance'
    },
    {
      value:'MAINTENANCE',
      label:'Maintenance'
    },
  ] 

  export default productTypes